<template>
  <div>
    <card class="m-auto workspace workspace__auth-card">
      <div class="p-4 workspace__auth_card">
        <div class="workspace__auth__img_container">
          <h3 class="font-weight-700 my-2">{{ $t('workspace.hi') }} {{ user?.name }},</h3>
          <h3 class="font-weight-700 text-center">{{ $t('workspace.selectWs') }}</h3>
        </div>
        <div v-if="loaded" class="workspace__auth__card_list">
          <div v-for="workSpace in userWorkspaces" :key="workSpace.workspace.id"
            class="workspace__auth__card_list--item">
            <h3>{{ workSpace.workspace.name }}</h3>
            <a @click.prevent="selectWorkspace(workSpace.workspace)">
              <base-button type="secondary" class="btn-fill my-0 workspace__select_button">
                {{ $t('seats.select') }}
              </base-button>
            </a>
          </div>
        </div>
        <el-skeleton :count="2" animated :loading="!loaded">
          <template slot="template">
            <el-skeleton-item variant="div" />
          </template>
        </el-skeleton>

        <base-button v-if="userRole != 'user'" @click.prevent="createWorkspace()" type="primary"
          :class="['btn-fill mt-4 py-3 px-3 w-100 btn_submit_form', !hasAvailableWorkspace ? 'disabled' : '']">
          <i class="fa-regular fa-circle-plus mr-2"></i> {{ $t('workspace.management.add') }}
        </base-button>
      </div>
    </card>

    <!-- <WorkspaceCreate v-if="userRole != 'user'" @changeStep="changeStep" v-show="workSpaceCreationStep == 1"/>
    <WorkspaceCreateAddUser v-if="userRole != 'user'" :workSpaceName="this.workSpaceName" @changeStep="changeStep" v-show="workSpaceCreationStep == 2"/> -->

  </div>
</template>

<script>
import Vue from 'vue';
import { Skeleton, SkeletonItem } from 'element-ui';

Vue.use(Skeleton);
Vue.use(SkeletonItem);

export default {
  data() {
    return {
      loaded: false,
      workSpacePesonal: {
        'workspace': {
          'id': null,
          'name': 'Personal'
        }
      }
    };
  },
  computed: {
    avatar() {
      return this.$store.getters.getAvatar;
    },
    user() {
      return this.$store.getters.getUser;
    },
    userWorkspaces() {
      return [].concat(this.workSpacePesonal, this.$store.getters.userWorkspaces);
    },
    hasAvailableWorkspace() {
      return this.$store.getters.getAvailableWorkspaces > 0;
    },
    userRole() {
      return this.$store.getters.getUserRole;
    }
  },
  mounted() {
    Promise.all([
      //this.$store.dispatch('fetchWorkspaces'),
      this.$store.dispatch('fetchUserWorkspaces')
    ]).then(() => { this.loaded = true });
  },
  methods: {
    selectWorkspace(workspace) {
      this.$store.dispatch('setWorkspace', workspace);
      this.$router.push({ path: '/dashboard' });
    },
    logout() {
      this.$store.dispatch('logout');
    },
    createWorkspace() {
      this.$router.push({ name: 'workspace.create' });
    }
  },
  created() {
    setTimeout(() => {
      let docClasses = document.body.classList;
      docClasses.add('white-content');
    }, 100);
  },
};
</script>

<style lang="scss">
.workspace__auth_card {
  .el-skeleton__div {
    width: 100%;
    height: 68px;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

}
</style>