<template>
  <div>
    <el-dialog class="version_history__dialog" :visible.sync="showHistoryDialog">
      <VersionHistoryDialog :showVersionValue="showHistoryDialog" :order="order" />
    </el-dialog>
    <!-- TEXT TOOLS -->
    <div v-if="order.category === 'text'" class="d-sm-inline-flex">
      <!-- Replace highlighted text -->

      <!--TODO CHECK THIS CONTROL -->

      <el-tooltip v-if="
        user.extra_info?.has_link_builder &&
        orderLinkBuilder &&
        Object.keys(orderLinkBuilder).length > 0
      " content="Replace" :open-delay="300" placement="top">
        <base-button icon size="sm" class="btn-simple mr-2" @click="replacelinkBuilder()"
          :disabled="!enablelinkBuilderBtn">
          <div>
            <i class="fa-solid fa-arrows-repeat"></i>
          </div>
        </base-button>
      </el-tooltip>

      <!-- Download Saved Images -->
      <el-tooltip v-if="savedImages" content="Download Images" :open-delay="300" placement="top">
        <base-button icon size="sm" class="btn-simple mr-2" @click="downloadImages(savedImages)">
          <div>
            <i class="fa-regular fa-image"></i>
          </div>
        </base-button>
      </el-tooltip>

      <!-- Trigger Free Prompt Edit -->
      <el-tooltip :content="$t('toolbar.refineTooltip')" :disabled="canOpenFreePromptEditModal" :open-delay="300"
        placement="top">
        <div>
          <base-button :disabled="!canOpenFreePromptEditModal" id="btnFreePrompt" class="btn-simple mr-2"
            @click="openAiEditPromptModal">
            {{ $t('toolbar.refineAi') }}
          </base-button>
        </div>
      </el-tooltip>

      <!-- Copy -->
      <el-tooltip :content="$t('toolbar.copy_plain')" :open-delay="300" placement="top">
        <base-button icon size="sm" class="btn-simple mr-2" @click="copyText(true)">
          <div>
            <i class="fa-solid fa-copy"></i>
          </div>
        </base-button>
      </el-tooltip>

      <el-tooltip :content="$t('toolbar.copy_html')" :open-delay="300" placement="top">
        <base-button icon size="sm" class="btn-simple mr-2" @click="copyText()">
          <div>
            <i class="fa-solid fa-code"></i>
          </div>
        </base-button>
      </el-tooltip>

      <!-- Save -->
      <el-tooltip :content="$t('toolbar.save_order')" :open-delay="300" placement="top">
        <base-button icon size="sm" class="btn-simple mr-2" @click="saveOrder(order?.content, order?.title)">
          <div>
            <i class="fa-solid fa-floppy-disk"></i>
          </div>
        </base-button>
      </el-tooltip>

      <!-- Version History -->
      <el-tooltip v-show="showVersionHistory && checkResForVersionHistory" :content="$t('versionHistory.title')"
        :open-delay="300" placement="top">
        <base-button icon size="sm" class="btn-simple mr-2" @click="versionHistory(order)">
          <div>
            <i class="fa-solid fa-clock-rotate-left"></i>
          </div>
        </base-button>
      </el-tooltip>

      <!-- Menu button -->
      <base-dropdown tag="div" title-tag="a" class="nav-item d-inline" title-classes=""
        menu-classes="dropdown-navbar dropdown-history dropdown__container_menu order__container_menu">
        <template slot="title">
          <base-button icon size="sm" class="btn-simple mr-2">
            <div>
              <i class="fa-solid fa-share-nodes"></i>
            </div>
          </base-button>
        </template>

        <!-- Copy button -->
        <div class="order__dropdown_container">
          <h6>Copy link</h6>
          <li class="order__dropdown_container_list_item" @click="copyUrl(location)">
            <i class="fa-sharp fa-regular fa-link"></i>
            <label>{{ location }}</label>
          </li>
        </div>

        <!-- Download  -->
        <div class="order__dropdown_container">
          <h6>Download</h6>
          <li @click="download(order)" class="order__dropdown_container_list_item">
            <i class="fa-solid fa-file-word"></i>
            <label> .doc format </label>
          </li>
          <li @click="download(order, 'pdf')" class="order__dropdown_container_list_item">
            <i class="fa-solid fa-file-pdf"></i>
            <label> .pdf format </label>
          </li>
        </div>

        <!-- Upload -->
        <!-- publish -->
        <div class="order__dropdown_container">
          <h6>Upload</h6>
          <li v-for="website in websites" :key="website.id" @click="publish(website.id)"
            class="order__dropdown_container_list_item">
            <i class="fa-solid fa-globe-pointer"></i>
            <label>
              {{ website.name }}
            </label>
          </li>
          <li @click="publish('create')" class="order__dropdown_container_list_item">
            <i class="fa-solid fa-plus-circle"></i>
            <label>
              {{ $t("website.create") }}
            </label>
          </li>
        </div>
      </base-dropdown>

      <span v-if="loading" class="loader"></span>
      <!-- Form -->
      <el-dialog :title="$t('workspace.settings.addNewWs')" :visible.sync="dialogFormVisible" class="dialog-sm">
        <WebsiteCreateForm :key="formComponentKey" @hideForm="dialogFormVisible = false"
          @websiteCreated="setNewWebsite" />
      </el-dialog>
    </div>

    <!-- OTHER TOOLS -->
    <div v-else>
      <!-- Download -->
      <el-tooltip v-if="showDefaultDownload" content="Download" :open-delay="300" placement="top">
        <base-button icon size="sm" class="btn-link border-download-button mr-2" @click="download(order)">
          <div>
            <i class="fa-solid fa-cloud-download-alt"></i>
          </div>
        </base-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import * as JSZip from "jszip/dist/jszip.min.js";
import { copyToClipboard } from "@/util/utils";
import { updateOrder } from "@/services/ordersService";
import swal from "sweetalert2";
import { mapGetters } from "vuex";
import { Select, Option, Dialog, Message } from "element-ui";
import WebsiteCreateForm from "../Pages/ControlPanel/Workspace/WorkspaceSettings/WebsiteSettings/WebsiteCreateForm.vue";
import VersionHistoryDialog from "./VersionHistoryDialog.vue";

export default {
  components: {
    WebsiteCreateForm,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Message.name]: Message,
    VersionHistoryDialog,
  },
  data() {
    return {
      selectedWebsite: null,
      loading: false,
      dialogFormVisible: false,
      formComponentKey: 0,
      showHistoryDialog: false,
      location: null,
      canOpenFreePromptEditModal: false,
    };
  },
  props: {
    showDefaultDownload: {
      type: Boolean,
      default: true,
    },
    parentContent: {
      type: String,
      default: "",
    },
    showVersionHistory: Boolean,
    saveTitleSeoArticle: {
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      order: "getOrder",
      user: "getUser",
    }),

    orderLinkBuilder() {
      return this.order?.json_data?.api_input?.linked_keywords;
    },

    enablelinkBuilderBtn() {
      return this.order.content && this.orderLinkBuilder;
    },
    workspaceId() {
      return this.$store.getters.selectedWorkspace.id;
    },
    websites() {
      return this.$store.getters.getWebsites;
    },
    checkResForVersionHistory() {
      if (window.innerWidth <= 1024) {
        return false;
      }
      return true;
    },

    savedImages() {
      if (this.order?.json_data?.saved_images) {
        return this.order?.json_data?.saved_images;
      }

      if (this.$store.getters.savedImages.length) {
        return this.$store.getters.savedImages;
      }

      return null;
    },
  },

  watch: {
    showHistoryDialog(isVisible) {
      this.$emit("showDialog", isVisible);
    },
    dialogFormVisible(isVisible) {
      this.$emit("showDialog", isVisible);
    },
  },
  methods: {
    /**
     * Replace &lt; with < AND &gt; with >
     * @param {string} text
     */
    replaceHtmlEntities(text) {
      return text.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    },

    /**
     * Return an array of object containing the initialIndex and finalIndex of the found anchor tags.
     * @param {string} text
     */
    findAnchorTag(text) {
      // Regular expression to match anchor tags
      const regex = /(<|&lt;)a/g;
      const closingTag = "</a>";

      // Array to store results
      const anchorTagIndexes = [];

      // Executing the regular expression on the input text
      let match;
      while ((match = regex.exec(text)) !== null) {
        // Get the initial and final indexes of the match
        const initialIndex = match.index;
        const finalIndex = text.indexOf(closingTag, regex.lastIndex) + closingTag.length;

        // Add the indexes to the array
        anchorTagIndexes.push({ initialIndex, finalIndex });
      }

      return anchorTagIndexes;
    },

    openAiEditPromptModal() {
      this.$root.$emit("rich-text-editor:open-prompt-modal");
    },

    /**
     * Replace all linkBuilder keywords with the matching url,
     * if not already wrapped by an anchor tag.
     */
    async replacelinkBuilder() {
      this.$preloader.fadeIn();

      let params = {
        text: this.order.content,
        linked_keywords: this.orderLinkBuilder,
        exclude_first_paragraph: true,
        exclude_headers: true,
        first_occurrence_only: true,
      };

      await this.$store
        .dispatch("anchorLinkingKeyword", params)
        .then((response) => {
          this.order.content = response.api_output.linkingkwds_text ?? this.order.content;
          this.$preloader.fadeOut();
        })
        .catch((error) => {
          this.$preloader.fadeOut();
          Message({
            showClose: true,
            message: error?.response?.data?.message ?? error?.message ?? "Generic error",
            type: "error",
          });
        });

      /*
      // Replace HTML entities
      this.order.content = this.replaceHtmlEntities(this.order.content);
      // Replace all existing keywords
      this.orderLinkBuilder.forEach((link) => {
        const keyword = link.keyword;
        const url = link.url;
        const stringToBeReplaced = `<a href="${url}" target="_blank">${keyword}</a>`;

        let keywordIndex = 0;
        // let startsFromIndex = 0;

        while (keywordIndex >= 0) {
          // Get all anchora objects containing the position indexes
          const anchorTags = this.findAnchorTag(this.order.content);

          // If it's the first round of the loop starts from the beginning,
          // if not starts from the end of the previous keyword found
          let startsFromIndex = keywordIndex === 0 ? keywordIndex : keywordIndex + 1;

          // Find new keyword index position
          keywordIndex = this.order.content.indexOf(keyword, startsFromIndex);

          if (keywordIndex >= 0) {

            // Check if the found keyword in inside an anchor tag by compairing their indexes
            let isInsideAnchorTag = false;
            anchorTags.forEach(anchorTag => {
              if (keywordIndex >= anchorTag.initialIndex && keywordIndex <= anchorTag.finalIndex) {
                isInsideAnchorTag = true;
              }
            })

            // If not in an anchor tag, replace the keyword and rebuild the order content
            if (!isInsideAnchorTag) {
              // Replace
              this.order.content = this.order.content.substring(0, keywordIndex) + stringToBeReplaced + this.order.content.substring(keywordIndex + keyword.length, this.order.content.length)
            }
          }
        }

        this.$preloader.fadeOut()
      });
      */
    },

    handlelinkBuilder() {
      const _this = this;

      swal
        .fire({
          title: `Are you sure you want to replace all keywords with the corresponding link?`,
          text: ``,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton:
              "btn btn-success btn-fill btn_submit_form btn_profile btn-primary",
            cancelButton: "btn btn-danger btn-fill btn_add_link btn-secondary",
          },
          confirmButtonText: "Yes, replace!",
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            _this.replacelinkBuilder();
            swal.fire({
              title: "Replaced",
              text: `All keywords have been replaced.`,
              icon: "success",
              customClass: {
                confirmButton:
                  "btn btn-success btn-fill btn_submit_form btn_profile btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
    },

    copyText(parsing = null) {
      let textContent;
      if (this.order.slug === "blog_article_with_social_posts") {
        textContent = this.order.content.blog_post_html;
      } else if (this.order.slug === "pitch_creator") {
        textContent = this.order.content.blog_post_html;
      } else {
        textContent =
          this.order.slug === "blog_post_paragraphs"
            ? this.parentContent
            : this.order.content;
      }

      if (parsing) {
        const parser = new DOMParser();
        const parsedText = parser.parseFromString(textContent, "text/html");
        copyToClipboard(parsedText.documentElement.textContent, "copyText");
        return;
      }
      copyToClipboard(textContent, "copyText");
    },

    async downloadImages(images) {
      const blobs = [];
      images.forEach((image) => {
        const base64String = image.base_64_url.replace(
          /^data:image\/?[A-z]*;base64,/,
          ""
        );
        blobs.push(this.base64ToBlob(base64String, "image/jpg"));
      });

      const zip = new JSZip();

      blobs.forEach((blob, index) => {
        zip.file(`image${index + 1}.jpg`, blob);
      });

      const zipFile = await zip.generateAsync({ type: "blob" });

      this.downloadImagesZipFile(zipFile);
    },

    base64ToBlob(base64String, contentType = "") {
      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
      }

      const byteArray = new Uint8Array(byteArrays);
      return new Blob([byteArray], { type: contentType });
    },

    downloadImagesZipFile(file) {
      const a = document.createElement("a");

      a.download = "images.zip";

      const url = URL.createObjectURL(file);
      a.href = url;
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();
      a.remove();

      URL.revokeObjectURL(url);
    },

    copyUrl(location) {
      navigator.clipboard.writeText(location);
    },

    download(row, format = null) {
      this.$store.dispatch("downloadOrder", {
        orderDetailId: row.order_detail_id,
        format,
      });
    },

    async saveOrder() {
      let response;
      const textContent =
        this.order.slug === "blog_post_paragraphs"
          ? this.parentContent
          : this.order.content;

      response = await updateOrder(
        this.order.order_detail_id,
        textContent,
        this.saveTitleSeoArticle ? this.saveTitleSeoArticle : this.order?.title
      );

      if (response.status == "success") {
        this.$message({
          showClose: true,
          message: "Document saved successfully",
          type: "success",
        });
        this.$store.dispatch("showOrderHistory", this.order?.order_detail_id);

        this.$emit("saved");
      } else {
        this.$message({
          showClose: true,
          message: "There was an error saving document",
          type: "error",
        });
      }
    },

    async publish(id) {
      if (id == "create") {
        this.selectedWebsite = "create";
      }
      if (this.selectedWebsite == "create") {
        this.formComponentKey++;
        this.selectedWebsite = null;
        this.dialogFormVisible = true;
      } else {
        this.loading = true;
        let params;
        if (id) {
          this.selectedWebsite = id;
        }
        params = {
          order_det_id: this.order.order_detail_id,
          website_id: this.selectedWebsite,
        };
        await this.$store
          .dispatch("publishWebsite", params)
          .then(() => (this.selectedWebsite = null));
        this.loading = false;
      }
    },

    setNewWebsite(newWebsiteId) {
      this.selectedWebsite = newWebsiteId;
      this.publish();
    },

    versionHistory(order) {
      this.$store.dispatch("showOrderHistory", this.order.order_id);
      this.showHistoryDialog = true;
    },
  },
  mounted() {
    this.$store.dispatch("fetchWebsites", this.workspaceId);
    this.location = window.location.href;

    this.$root.$on("rich-text-editor:prompt-modal-enabled", (isEnabled) => {
      this.canOpenFreePromptEditModal = isEnabled;
    });
  },
};
</script>

<style land="scss">
#btnFreePrompt {
  width: 135px;
}

@media only screen and (min-width: 992px) {
  .dropdown-menu.dropdown-navbar.dropdown-history {
    right: 0 !important;
    left: auto !important;
  }
}
</style>
