<template>
    <div>
        <h1 class="text-center">{{ $t('workspace.addPeople') }} <br> "{{ workSpaceName }}" {{ $t('workspace.workspace') }}
        </h1>
        <div class="d-flex row m-auto justify-content-center">
            <card class="workspace workspace__auth-card mr-lg-4 col-lg-9 col-md-12 col-sm-12 h-100 px-0 pb-2">
                <h4 slot="header" class="font-weight-bold">{{ $t('workspace.chooseMembers') }}</h4>
                <div class="px-4 workspace__auth_card">
                    <div class="row">
                        <div class="col-6 px-0">
                            <label>{{ $t('workspace.filterLetter') }}</label>
                            <div class="search-bar input-group searchbar_dashboard w-100 ml-0">
                                <el-select :popper-append-to-body="false" class="select-primary w-100 " filterable
                                    size="medium" v-model="selectedLetter">
                                    <el-option v-for="item in navItems" :key="item" class="select-primary" :value="item"
                                        :label="item != 'All' ? item : item + ' (' + userSeats.length + ')'">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-6 px-0">
                            <base-input class="search-input mb-0 ml-2" :label="$t('generateButton.search')" type="text"
                                :placeholder="$t('workspace.searchInput')" v-model="searchInput">
                            </base-input>
                            <div class="search-icon"></div>
                        </div>
                    </div>
                    <div class="row workspace__add_user_list">
                        <div v-for="userSeat in userList" :key="userSeat.user.id"
                            class="workspace__add_user_list--item">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input @click="addUser(userSeat)" class="form-check-input" type="checkbox"
                                        :checked="checkSelected(userSeat)" />{{ userSeat.user.email }}
                                    <span class="form-check-sign"></span>
                                </label>
                            </div>
                            <p class="col-3 px-0 text-center">{{ $t('seats.roles.' + userSeat.role) }}</p>
                            <label style="max-width:80px" class="mb-0 order__table-status text-center"
                                :class="`order__table-status--${userSeat.user.status}`">
                                {{ capitalizeFirstLetter(userSeat.user.status) }}
                            </label>
                        </div>
                    </div>
                </div>
            </card>
            <card class="workspace pb-2 px-0 workspace__auth-card col-lg-2 col-md-12 col-sm-12 mb-auto">
                <h4 slot="header" class="font-weight-bold">{{ $t('workspace.preview') }}</h4>
                <div class="p-0 workspace__auth_card">
                    <div :class="userSelected.length ? 'p-0' : ''" class="workspace__user_selected">
                        <i v-show="!userSelected.length" class="fa-solid fa-user-plus"></i>
                        <p style="color: #566A7F !important" v-show="!userSelected.length">{{
                            $t('workspace.selectUsers') }}</p>
                        <label v-for="userSeat in userSelected" :key="userSeat.user.id">{{ userSeat.user.email
                            }}</label>
                    </div>
                    <base-button @click="createWrorkspace" type="primary"
                        class="btn-fill mt-3 py-3 px-3 w-100 btn_submit_form" :disabled="loading">
                        {{ $t('workspace.confirm') }} ({{ userSelected.length }} {{ $t('generic.selected') }})
                    </base-button>
                    <base-button @click="goBack" type="secondary" class="btn-fill mt-2 py-3 px-3 w-100 btn_add_link">
                        {{ $t('workspace.cancel') }}
                    </base-button>
                </div>
            </card>
        </div>
    </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import { capitalizeFirstLetter } from '@/util/strings';
export default {
    props: {
        workSpaceName: {
            type: String,
        },
    },
    components: {
        [Select.name]: Select,
        [Option.name]: Option,

    },
    data() {
        return {
            loading: false,
            userSeats: [],
            userSelected: [],
            selectedLetter: 'All',
            searchInput: '',
            navItems: ['All', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'Y', 'Z']
        };
    },
    computed: {
        userList() {
            let filteredList = this.userSeats;
            if (this.selectedLetter != 'All') {
                filteredList = this.userSeats.filter(
                    (user_seat) => user_seat.user.email.charAt(0).toLowerCase() == this.selectedLetter.toLowerCase()
                )
            }
            if (this.searchInput != '') {
                filteredList = filteredList.filter(
                    (user_seat) => user_seat.user.email.toLowerCase().includes(this.searchInput.toLowerCase())
                )
            }
            return filteredList;
        },
    },
    mounted() {
        this.$store.dispatch('fetchUserSeats').then(() => {
            this.userSeats = this.$store.getters.getUserSeats.filter(
                (user_seat) => user_seat.role !== 'master'
            );
        });
    },
    methods: {
        capitalizeFirstLetter,

        createWrorkspace() {
            this.$preloader.fadeIn();
            this.loading = true;
            this.$store.dispatch('createWorkspace', {
                'name': this.workSpaceName,
                'users': this.userSelected
            }).then(() => {
                Promise.all([
                    //this.$store.dispatch('fetchWorkspaces'),
                    this.$store.dispatch('fetchUserWorkspaces')
                ]).then(() => {
                    this.$router.push({ name: 'workspace.login' });
                })
            }).finally(() => {
                this.$preloader.fadeOut();
                this.loading = false;
            })
        },

        goBack() {
            this.$emit('changeStep', 1);
        },

        addUser(user) {
            if (this.userSelected.some((e) => e.user_id === user.user_id)) {
                this.userSelected = this.userSelected.filter(
                    (item) => item.user_id !== user.user_id
                );
            } else {
                this.userSelected.push(user);
            }

            this.sortUserByEmail(this.userSelected);
        },

        sortUserByEmail(users) {
            this.userSelected = users.slice().sort(function (a, b) {
                if (a.user.email < b.user.email) {
                    return -1;
                } else if (a.user.email > b.user.email) {
                    return 1;
                }
                return 0;
            });
        },

        checkSelected(user) {
            return this.userSelected.some(elem => elem === user);
        }
    },
};
</script>

<style lang="scss">
.search-bar,
.search-input input {
    height: 42px !important;

    &:focus,
    &:focus-visible {
        border-color: #D8D8D8 !important;
    }
}

.search-input input {
    padding-right: 35px !important;
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 55%;

    &:before {
        color: #004583;
        font-family: "Font Awesome 5 Free" !important;
        content: "\f002" !important;
        font-weight: 900 !important;
    }

    &:after {
        content: '';
        height: 100%;
        width: 0;
        display: inline-block;
        vertical-align: middle;
    }
}
</style>